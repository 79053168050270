import React from "react"
import Layout from "../layouts/default"
import Seo from "../components/seo"

import Stack from "../components/stack"
import PageTitle from "../components/page-title"
import OpeningTimes from "../components/opening-times"
import Heading from "../components/heading"
import Paragraph from "../components/paragraph"
import Grid from "../components/grid"
import TextLink from "../components/textlink"
import Anchor from "../components/anchor"
import Breadcrumb from "../components/breadcrumb"

const Kontakt = () => {
  return (
    <Layout backdrop="aussen">
      <Seo
        title="Kontakt"
        description="Adresse, Öffnungszeiten und Ansprechpartner des Hölderlinturms"
      />

      <Stack>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Kontakt",
                link: "/kontakt",
              },
            ]}
          />
          <PageTitle>Kontakt</PageTitle>

          <Stack space={12} dividers>
            <Grid columns={[1, 2]}>
              <Heading level={5}>Adresse</Heading>
              <Paragraph size={[2, 3, 4]}>
                Universitätsstadt Tübingen <br />
                Hölderlinturm <br />
                Bursagasse 6 <br />
                72070 Tübingen
              </Paragraph>
            </Grid>
            <Grid columns={[1, 2]}>
              <Heading level={5}>Kontakt</Heading>
              <Stack space={6}>
                <Paragraph size={[2, 3, 4]}>
                  Allgemeine Anfragen <br />
                  Tel.: 07071 204-1860
                </Paragraph>
                <Paragraph size={[2, 3, 4]}>
                  Museumskasse
                  <br />
                  Tel.: 07071 204-1862
                </Paragraph>
                <Paragraph size={[2, 3, 4]}>
                  <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                    hoelderlinturm@tuebingen.de
                  </TextLink>
                </Paragraph>
              </Stack>
            </Grid>
            <Grid columns={[1, 2]}>
              <Heading level={5}>
                Zuständigkeiten und <br />
                Ansprechpersonen
              </Heading>
              <Stack space={6}>
                <Paragraph size={[2, 3, 4]}>
                  Leitender Kurator
                  <br />
                  Florian Mittelhammer
                  <br /><br />
                  Tel.: 07071 204-1861
                  <br /><br />
                  <TextLink href="mailto: florian.mittelhammer@tuebingen.de">
                     florian.mittelhammer@tuebingen.de
                  </TextLink>
                </Paragraph>
              </Stack>
            </Grid>
            <Grid columns={[1, 2]}>
              <Anchor id="oeffnungszeiten">
                <Heading level={5}>Öffnungszeiten</Heading>
              </Anchor>
              <OpeningTimes size={[2, 3, 4]} />
            </Grid>
            <Grid columns={[1, 2]}>
              <Heading level={5}>Anfahrt</Heading>
              <Stack space={6}>
                <Paragraph size={[2, 3, 4]}>
                  Vom Tübinger Hauptbahnhof ist man zu Fuß in 10-15 Minuten über
                  die Neckarbrücke zum Hölderlinturm spaziert. Man kann auch mit
                  dem Bus fahren. Die nächstgelegene Haltestelle ist die
                  Neckarbrücke. Dort halten alle Linien in Richtung Innenstadt.
                </Paragraph>
                <Paragraph size={[2, 3, 4]}>
                  Parkmöglichkeiten gibt es im: <br />
                  Neckar-Parkhaus, Wöhrdstraße 11, 72072 Tübingen <br />
                  Parkhaus Post-/Europa-Platz, Europastraße, 72072 Tübingen{" "}
                  <br />
                  Parkhaus am Stadtgraben, Am Stadtgraben 5, 72070 Tübingen
                </Paragraph>
              </Stack>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Kontakt
